







































import {Component, Vue} from 'vue-property-decorator';
import {PublicBoardTemplates} from "@/graphql/boardTemplate";

@Component({
  components: {},
  apollo: {
    PublicBoardTemplates: {
      query: PublicBoardTemplates,
    },
  }
})
export default class QuickSetup extends Vue {

}
